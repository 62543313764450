#container {
  display: grid;
  height: 100%;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    "header"
    "main"
    "footer";

  overflow: hidden;
}

main {
  grid-area: main;
  display: flex;
  justify-content: center;

  padding: 0.5rem 0;
  z-index: 10;
}
