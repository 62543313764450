.free-palestine {
  display: block;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: grey;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.flag {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stripe {
  flex: 1;
  width: 0;

  animation-name: slideIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.black {
  height: 33vh;
  background-color: black;
  transition: background-color 0.1s ease-in-out;
  user-select: none;
}

.black:active {
  background-color: #1a1a1a; /* 10% lighter */
}

.white {
  height: 34vh;
  display: flex;
  align-items: center;
  background-color: white;
  text-align: center;
  user-select: none;
  transition: background-color 0.1s ease-in-out;
}

.space-holder {
  width: 33vw;
}

.white h1 {
  font-family: "Bebas Neue", sans-serif;
  color: black;
  margin: 0;
  padding: 0;
  font-size: 6.5rem;
  letter-spacing: -5px;
  width: 100%;
  cursor: default;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
}

.white:active {
  background-color: #e6e6e6; /* 10% darker*/
}

.green {
  height: 33vh;
  background-color: #009736;
  transition: background-color 0.1s ease-in-out;
  user-select: none;
}

.green:active {
  background-color: #008831; /* 10% darker */
}

.triangle {
  position: absolute;
  height: 100vh;
  width: 0;
  background-color: #ee2a35;
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
  transition: background-color 0.1s ease-in-out;
  user-select: none;

  animation-name: slideIn33;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.triangle:hover {
  cursor: pointer;
}

.triangle:active {
  background-color: #e9131f; /* 10% darker */
}

@keyframes fadeIn {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@keyframes slideIn {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes slideIn33 {
  from {
    width: 0;
  }
  to {
    width: 33%;
  }
}
